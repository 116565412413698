<template>
  <section class="hero hero-diagnostic">
    <div class="container">
      <div class="__content">
        <div class="hero-title">Диагностика</div>
        <img
          src="@/assets/images/diagnostic.webp"
          alt=""
          class="hero-diagnostic--image--mobile visible-xs"
        />
        <p class="hero-text">
          Начните путь к Работающему Эмоциональному Интеллекту
        </p>
        <div class="hero-price display--flex">
          <div class="__current">от 2000 ₽</div>
        </div>
        <div class="hero-action btn-action display--flex">
          <button @click="toDiagnosticCatalog" class="btn btn-accent">
            Пройти диагностику
          </button>
          <button @click="toGame" class="btn btn-o">
            Кого вы возьмете на работу
          </button>
        </div>
      </div>
      <img
        src="@/assets/images/diagnostic.png"
        alt=""
        class="hero-diagnostic--image hidden-xs"
      />
    </div>
  </section>

  <section class="index-section s-diagnostic--about">
    <div class="container">
      <div class="s-product--about-sm--grid">
        <div class="__inner display--grid grid-4">
          <div class="__item">
            <img src="@/assets/images/svg/Top_Manager.svg" />
            <div class="__title">Руководителям</div>
            <p>
              С помощью диагностики можно измерить потенциал команды, определить
              собственные сильные стороны для качественного улучшения
              управленческого стиля, развития soft skills и повышения KPI у себя
              и команды.
            </p>
          </div>
          <div class="__item">
            <img src="@/assets/images/svg/HR,T&D.svg" />
            <div class="__title">HR- и T&D-специалистам</div>
            <p>
              Диагностика упрощает и ускоряет процессы найма, построение
              траекторий карьерного развития сотрудника. Специалист определяет
              зоны роста команды и подбирает программы для раскрытия потенциала
              сотрудника.
            </p>
          </div>
          <div class="__item">
            <img src="@/assets/images/svg/Team.svg" />
            <div class="__title">Линейному персоналу</div>
            <p>
              Диагностика повышает вовлеченность команды в процесс развития
              эмоционального интеллекта и смежных надпрофессиональных
              компетенций, открывает пути развития специалиста как лидера и
              командного игрока.
            </p>
          </div>
          <div class="__item">
            <img src="@/assets/images/svg/Trainer.svg" />
            <div class="__title">Коучам и тренерам</div>
            <p>
              Диагностика важна для повышения мотивации заказчика в развитии
              эмоционального интеллекта. Тестирование помогает выстроить
              индивидуальный план работы с клиентом, а также повысить
              собственные компетенции.
            </p>
          </div>
        </div>
      </div>

      <div class="s-product--about-text">
        <h1 class="__title">
          Диагностика — первый шаг на пути развития эмоционального интеллекта
        </h1>
        <p>
          Основана на научном подходе ученых из Йельского университета,
          института психологии РАН и основателя теории ЭИ Дэвида Карузо. Это
          единственная русскоязычная научно-обоснованная диагностика (ТЭИ),
          которая дает точное представление об уровне эмоционального интеллекта
          тестируемого.
        </p>
        <div class="s-product--about-image">
          <div class="__logos display--flex">
            <img src="@/assets/images/diagnostic-logo-01.svg" alt="" />
            <img src="@/assets/images/diagnostic-logo-02.svg" alt="" />
          </div>
          <img
            src="@/assets/images/diagnostic-content-image.webp"
            alt=""
            class="hidden-xs"
          />
          <img
            src="@/assets/images/diagnostic-content-image-mobile.webp"
            alt=""
            class="visible-xs"
          />
          <div class="submenu">
            <div class="submenu__title">Скачать пример отчёта в PDF</div>
            <div class="submenu__links">
              <ul>
                <li>
                  <a download href="/files/Стандартный.pdf"
                    >Стандартный отчет</a
                  >
                </li>
                <li><a download href="/files/Полный.pdf">Полный отчет</a></li>
                <li>
                  <a download href="/files/Стартовый.pdf">Стартовый отчет</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="s-product--about-sm--grid display--grid">
        <div class="__inner display--grid grid-3">
          <div class="__item">
            <p>
              Тестирование проходит в онлайн-формате. Доступно для прохождения
              на компьютере и телефоне. Решение заданий требует не больше 30-40
              минут.
            </p>
          </div>
          <div class="__item">
            <p>
              Тестируемому предложены разноформатные задания, которые выявляют
              уровень развития каждой способности (ветви) эмоционального
              интеллекта.
            </p>
          </div>
          <div class="__item">
            <p>
              После прохождения тестирования в личном кабинете появляются
              результаты диагностики: ЭИ-профиль с баллами по каждой (ветви)
              эмоционального интеллекта.
            </p>
          </div>
        </div>
      </div>

      <div class="s-product--about-sm--grid display--grid">
        <div class="__title __title-mb">Преимущества диагностики</div>
        <div class="__inner display--grid grid-3">
          <div class="__item">
            <img src="@/assets/images/svg/Individual.svg" />
            <div class="__title">Нет аналогов</div>
            <p>
              Это единственная в России научно-обоснованная диагностика
              эмоционального интеллекта, которая дает точное представление об
              эмоциональном интеллекте тестируемого.
            </p>
          </div>
          <div class="__item">
            <img src="@/assets/images/svg/Online.svg" />
            <div class="__title">Интеграция и API</div>
            <p>
              Диагностика может встраиваться во внешние сервисы для удобства
              клиента с проведением тестирования, анализа полученных данных и
              дальнейшей работы с результатами прохождения теста.
            </p>
          </div>
          <div class="__item">
            <img src="@/assets/images/svg/Science-1.svg" />
            <div class="__title">Научная обоснованность</div>
            <p>
              Психометрическая проверка ТЭИ показала высокую степень надежности
              тестирования. Также наша методика обладает высокой степенью
              валидности и является стандантизированной.
            </p>
          </div>
          <div class="__item">
            <img src="@/assets/images/svg/Science.svg" />
            <div class="__title">Аналитика результатов</div>
            <p>
              Данные тестирования обрабатываются механизмами искусственного
              интеллекта, что повышает точность полученных результатов и
              минимизирует риск ошибки.
            </p>
          </div>
          <div class="__item">
            <img src="@/assets/images/svg/Big_data.svg" />
            <div class="__title">Big Data</div>
            <p>
              Мы используем инновационные методы и способы хранения и обработки
              информации для автоматизации и оптимизации анализа результатов
              тестирования.
            </p>
          </div>
          <div class="__item">
            <img src="@/assets/images/svg/Security.svg" />
            <div class="__title">Закон 152-ФЗ</div>
            <p>
              Мы гарантируем конфиденциальность ваших персональных данных на
              всех этапах работы, обработки информационных массивов, хранения и
              передачи данных.
            </p>
          </div>
        </div>
      </div>

      <div class="btn-action display--flex">
        <!-- <button @click="toDiagnosticCatalog" class="btn btn-accent">
          Пройти диагностику
        </button> -->
        <button
          @click="openDiagnosticModal = true"
          type="button"
          class="btn btn-o-accent"
        >
          Заказать комплект диагностик для сотрудников
        </button>
      </div>
    </div>
  </section>

  <section class="ei-game s-product--about-text">
    <div class="container">
      <div class="__title">Кого вы возьмёте на работу?</div>
      <p>
        После того, как у вас будет информация о диагностике сотрудников, Вам
        будет легче принимать решения об их приёме или повышении. Попробуйте
        себя в роли HR, выбирайте на основе ЭИ-профилей претендентов:
      </p>
      <game />
    </div>
  </section>

  <product-slider
    v-if="customProducts"
    class="diagnostic-slider"
    title="Стоимость и каталог диагностик"
    :products="customProducts"
  ></product-slider>

  <pop-up-form
    type="Diagnostic"
    postField
    diagnostic
    title="Заказ комплекта диагностик"
    v-if="openDiagnosticModal"
    @closeModal="openDiagnosticModal = false"
  ></pop-up-form>
</template>

<script>
import ProductSlider from "../../components/sliders/product-slider/ProductSlider.vue";
import PopUpForm from "../../components/pop-up-form/PopUpForm.vue";
import { ref } from "@vue/reactivity";
import { computed, onBeforeMount } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import Game from "../../components/game/Game.vue";
export default {
  name: "diagnostic-page",
  components: {
    ProductSlider,
    PopUpForm,
    Game,
  },

  setup() {
    useHead({
      title:
        "Диагностика эмоционального интеллекта для HR и T&D специалистов, руководителей и сотрудников",
      meta: [
        {
          property: "og:description",
          content:
            "Диагностика на основе (ТЭИ) упрощает и ускоряет процессы найма, построение траекторий карьерного развития сотрудника и позволяет измерить потенциал команды",
        },
        {
          property: "og:keywords",
          content:
            "оценка эмоционального интеллекта, диагностика эмоционального интеллекта, диагностика эмоционального состояния, теста эмоционального интеллекта, тест+ на эмоциональный интеллект, определить эмоциональный интеллект, тест +на эмоции",
        },
      ],
    });
    const store = useStore();
    let openDiagnosticModal = ref(false);
    let customProducts = computed(() => store.state.products.customProducts);

    const toDiagnosticCatalog = () => {
      document
        .querySelector(".diagnostic-slider")
        .scrollIntoView({ behavior: "smooth", block: "nearest" });
    };
    const toGame = () => {
      document
        .querySelector(".ei-game")
        .scrollIntoView({ behavior: "smooth", block: "nearest" });
    };

    onBeforeMount(() => {
      store.dispatch("products/getCustomProducts", "Диагностика");
    });

    return {
      openDiagnosticModal,
      toDiagnosticCatalog,
      customProducts,
      toGame,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/s-product";

.hero-diagnostic {
  height: 590px;
  padding-top: 180px;
}

.hero-diagnostic .__content {
  max-width: 50%;
}

.hero-product .hero-title,
.hero-diagnostic .hero-title {
  margin-bottom: 12px;
  font-size: 46px;
  line-height: 51px;
  letter-spacing: -0.02em;
}

.hero-diagnostic--image {
  position: absolute;
  top: -50px;
  right: -18px;
  width: auto;
  max-width: calc(50% + 30px);
  height: auto;
}

.s-diagnostic--game {
  padding-top: 0;
}

.s-diagnostic--game .__slider {
  margin-top: 35px !important;
}

.s-diagnostic--game .swiper-container {
  padding-bottom: 0 !important;
  border-radius: 10px !important;
}

.s-diagnostic--game .swiper-button-next,
.s-diagnostic--game .swiper-button-prev {
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.s-diagnostic--game .swiper-button-next {
  right: 35px !important;
}

.s-diagnostic--game .swiper-button-prev {
  left: 35px !important;
}

.game-item {
  align-items: center;
  justify-content: center;
  padding: 35px;
}

.game-item--title {
  max-width: 50%;
  margin-bottom: 35px;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
}

.game-item--body {
  align-items: center;
  justify-content: center;
}

.game-item--body span {
  margin: 30px;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
}

.game-item--body + .btn-action {
  margin-top: 35px;
}

.submenu {
  position: absolute;
  right: 0;
  bottom: 0;
  color: var(--accent);
  padding: 12px 20px 14px;
  font-size: 20px;
  cursor: pointer;

  &__title::before {
    position: absolute;
    display: block;
    content: "";
    top: 24px;
    right: 0;
    width: 0;
    height: 0;
    border-width: 5px 5px 0 5px;
    border-style: solid;
    border-color: var(--accent) transparent transparent transparent;
    transition: all 0.3s ease-in;
  }

  &:hover .submenu__links {
    display: block;
  }

  &__links {
    position: absolute;
    top: 46px;
    right: 0;
    display: none;
    margin: 0;
    padding: 10px 15px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(182, 196, 214, 0.5);
    z-index: 10;

    color: var(--color);
    font-size: 18px;
    ul {
      list-style: none;
      padding-left: 0;
      margin: 5px 0;
    }

    li {
      padding: 4px;
    }

    a {
      color: var(--color);
    }

    a:hover {
      color: var(--accent);
    }
  }
}

@media (max-width: 767px) {
  .submenu {
    position: relative;
    margin-top: 35px;
    display: inline-block;

    &__title {
      font-size: 18px;
    }

    &__links {
      width: 100%;
      right: 0;
      li {
        padding: 8px;
      }
    }
  }

  .hero-diagnostic {
    height: auto;
  }

  .hero-diagnostic--image--mobile {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  .hero-diagnostic .__content {
    max-width: 100%;
  }

  .game-item {
    padding: 20px;
  }

  .game-item--title {
    max-width: 100%;
    font-size: 24px;
    line-height: 29px;
  }

  .game-item--body {
    flex-direction: column;
  }

  .game-item--body img {
    width: 250px;
  }

  .game-item--body span {
    margin: 0 0 12px;
    font-size: 24px;
    line-height: 1em;
  }

  .s-diagnostic--game .swiper-button-next {
    top: calc(50% + 50px);
    right: 0;
  }

  .s-diagnostic--game .swiper-button-prev {
    top: calc(50% + 50px);
    left: 0;
  }

  .game-item--body + .btn-action {
    margin-top: 8px;
  }
}
</style>